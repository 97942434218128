import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/pengs/Projects/xijingge/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Zu, Br, PrevNext, Text } from '../../components/Elements.jsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "地藏菩萨本愿经"
    }}>{`地藏菩萨本愿经`}</h1>
    <PrevNext prev='/dz/v4' next='/dz/v6' mdxType="PrevNext" /> <Br mdxType="Br" />
    <h3 {...{
      "id": "第五卷-地狱名号品"
    }}>{`第五卷 地狱名号品`}</h3>
    <Text mdxType="Text">
      <Zu yin="ěr" zi="尔" mdxType="Zu" />
  <Zu yin="shí" zi="时" mdxType="Zu" />
  <Zu yin="pǔ" zi="普" mdxType="Zu" />
  <Zu yin="xián" zi="贤" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="mó" zi="摩" mdxType="Zu" />
  <Zu yin="hē" zi="诃" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="bái" zi="白" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="rén" zi="仁" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yuàn" zi="愿" mdxType="Zu" />
  <Zu yin="wèi" zi="为" mdxType="Zu" />
  <Zu yin="tiān" zi="天" mdxType="Zu" />
  <Zu yin="lónɡ" zi="龙" mdxType="Zu" />
  <Zu yin="sì" zi="四" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="xiàn" zi="现" mdxType="Zu" />
  <Zu yin="zài" zi="在" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="qiè" zi="切" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="suō" zi="娑" mdxType="Zu" />
  <Zu yin="pó" zi="婆" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="jiè" zi="界" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="fú" zi="浮" mdxType="Zu" />
  <Zu yin="tí" zi="提" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="kǔ" zi="苦" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="suǒ" zi="所" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="chù" zi="处" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="hào" zi="号" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shǐ" zi="使" mdxType="Zu" />
  <Zu yin="wèi" zi="未" mdxType="Zu" />
  <Zu yin="lái" zi="来" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="mò" zi="末" mdxType="Zu" />
  <Zu yin="fǎ" zi="法" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhī" zi="知" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="ɡuǒ" zi="果" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="dā" zi="答" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="rén" zi="仁" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="jīn" zi="今" mdxType="Zu" />
  <Zu yin="chénɡ" zi="承" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="wēi" zi="威" mdxType="Zu" />
  <Zu yin="shén" zi="神" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="shì" zi="士" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="lì" zi="力" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="luè" zi="略" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="hào" zi="号" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="rén" zi="仁" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="fú" zi="浮" mdxType="Zu" />
  <Zu yin="tí" zi="提" mdxType="Zu" />
  <Zu yin="dōnɡ" zi="东" mdxType="Zu" />
  <Zu yin="fānɡ" zi="方" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="shān" zi="山" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hào" zi="号" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="wéi" zi="围" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qí" zi="其" mdxType="Zu" />
  <Zu yin="shān" zi="山" mdxType="Zu" />
  <Zu yin="hēi" zi="黑" mdxType="Zu" />
  <Zu yin="suì" zi="邃" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="rì" zi="日" mdxType="Zu" />
  <Zu yin="yuè" zi="月" mdxType="Zu" />
  <Zu yin="ɡuānɡ" zi="光" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hào" zi="号" mdxType="Zu" />
  <Zu yin="jí" zi="极" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="jiàn" zi="间" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yòu" zi="又" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="ā" zi="阿" mdxType="Zu" />
  <Zu yin="bí" zi="鼻" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="sì" zi="四" mdxType="Zu" />
  <Zu yin="jiǎo" zi="角" mdxType="Zu" />
  <Zu yin="；" zi="；" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="fēi" zi="飞" mdxType="Zu" />
  <Zu yin="dāo" zi="刀" mdxType="Zu" />
  <Zu yin="；" zi="；" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="huǒ" zi="火" mdxType="Zu" />
  <Zu yin="jiàn" zi="箭" mdxType="Zu" />
  <Zu yin="；" zi="；" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="jiá" zi="夹" mdxType="Zu" />
  <Zu yin="shān" zi="山" mdxType="Zu" />
  <Zu yin="；" zi="；" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="tōnɡ" zi="通" mdxType="Zu" />
  <Zu yin="qiānɡ" zi="枪" mdxType="Zu" />
  <Zu yin="；" zi="；" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="chē" zi="车" mdxType="Zu" />
  <Zu yin="；" zi="；" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="chuánɡ" zi="床" mdxType="Zu" />
  <Zu yin="；" zi="；" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="niú" zi="牛" mdxType="Zu" />
  <Zu yin="；" zi="；" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="yī" zi="衣" mdxType="Zu" />
  <Zu yin="；" zi="；" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="rèn" zi="刃" mdxType="Zu" />
  <Zu yin="；" zi="；" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="lǘ" zi="驴" mdxType="Zu" />
  <Zu yin="；" zi="；" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="yánɡ" zi="烊" mdxType="Zu" />
  <Zu yin="tónɡ" zi="铜" mdxType="Zu" />
  <Zu yin="；" zi="；" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="bào" zi="抱" mdxType="Zu" />
  <Zu yin="zhù" zi="柱" mdxType="Zu" />
  <Zu yin="；" zi="；" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="liú" zi="流" mdxType="Zu" />
  <Zu yin="huǒ" zi="火" mdxType="Zu" />
  <Zu yin="；" zi="；" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="ɡēnɡ" zi="耕" mdxType="Zu" />
  <Zu yin="shé" zi="舌" mdxType="Zu" />
  <Zu yin="；" zi="；" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="cuò" zi="剉" mdxType="Zu" />
  <Zu yin="shǒu" zi="首" mdxType="Zu" />
  <Zu yin="；" zi="；" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="shāo" zi="烧" mdxType="Zu" />
  <Zu yin="jiǎo" zi="脚" mdxType="Zu" />
  <Zu yin="；" zi="；" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="dàn" zi="啖" mdxType="Zu" />
  <Zu yin="yǎn" zi="眼" mdxType="Zu" />
  <Zu yin="；" zi="；" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="wán" zi="丸" mdxType="Zu" />
  <Zu yin="；" zi="；" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="zhēnɡ" zi="诤" mdxType="Zu" />
  <Zu yin="lùn" zi="论" mdxType="Zu" />
  <Zu yin="；" zi="；" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="fū" zi="鈇" mdxType="Zu" />
  <Zu yin="；" zi="；" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="yuē" zi="曰" mdxType="Zu" />
  <Zu yin="duō" zi="多" mdxType="Zu" />
  <Zu yin="chēn" zi="嗔" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="bái" zi="白" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="rén" zi="仁" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="wéi" zi="围" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="nèi" zi="内" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qí" zi="其" mdxType="Zu" />
  <Zu yin="shù" zi="数" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="xiàn" zi="限" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ɡènɡ" zi="更" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="jiào" zi="叫" mdxType="Zu" />
  <Zu yin="huàn" zi="唤" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bá" zi="拔" mdxType="Zu" />
  <Zu yin="shé" zi="舌" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="fèn" zi="粪" mdxType="Zu" />
  <Zu yin="niào" zi="尿" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="tónɡ" zi="铜" mdxType="Zu" />
  <Zu yin="suǒ" zi="锁" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huǒ" zi="火" mdxType="Zu" />
  <Zu yin="xiànɡ" zi="象" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huǒ" zi="火" mdxType="Zu" />
  <Zu yin="ɡǒu" zi="狗" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huǒ" zi="火" mdxType="Zu" />
  <Zu yin="mǎ" zi="马" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huǒ" zi="火" mdxType="Zu" />
  <Zu yin="niú" zi="牛" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huǒ" zi="火" mdxType="Zu" />
  <Zu yin="shān" zi="山" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huǒ" zi="火" mdxType="Zu" />
  <Zu yin="shí" zi="石" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huǒ" zi="火" mdxType="Zu" />
  <Zu yin="chuánɡ" zi="床" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huǒ" zi="火" mdxType="Zu" />
  <Zu yin="liánɡ" zi="梁" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huǒ" zi="火" mdxType="Zu" />
  <Zu yin="yīnɡ" zi="鹰" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jù" zi="锯" mdxType="Zu" />
  <Zu yin="yá" zi="牙" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="bāo" zi="剥" mdxType="Zu" />
  <Zu yin="pí" zi="皮" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǐn" zi="饮" mdxType="Zu" />
  <Zu yin="xiě" zi="血" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shāo" zi="烧" mdxType="Zu" />
  <Zu yin="shǒu" zi="手" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shāo" zi="烧" mdxType="Zu" />
  <Zu yin="jiǎo" zi="脚" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dào" zi="倒" mdxType="Zu" />
  <Zu yin="cì" zi="刺" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huǒ" zi="火" mdxType="Zu" />
  <Zu yin="wū" zi="屋" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="wū" zi="屋" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huǒ" zi="火" mdxType="Zu" />
  <Zu yin="lánɡ" zi="狼" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="qí" zi="其" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="ɡè" zi="各" mdxType="Zu" />
  <Zu yin="ɡè" zi="各" mdxType="Zu" />
  <Zu yin="fù" zi="复" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="xiǎo" zi="小" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="yī" zi="一" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="èr" zi="二" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="sān" zi="三" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="sì" zi="四" mdxType="Zu" />
  <Zu yin="、" zi="、" mdxType="Zu" />
  <Zu yin="nǎi" zi="乃" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qí" zi="其" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="mínɡ" zi="名" mdxType="Zu" />
  <Zu yin="hào" zi="号" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡè" zi="各" mdxType="Zu" />
  <Zu yin="ɡè" zi="各" mdxType="Zu" />
  <Zu yin="bù" zi="不" mdxType="Zu" />
  <Zu yin="tónɡ" zi="同" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="ɡào" zi="告" mdxType="Zu" />
  <Zu yin="pǔ" zi="普" mdxType="Zu" />
  <Zu yin="xián" zi="贤" mdxType="Zu" />
  <Zu yin="pú" zi="菩" mdxType="Zu" />
  <Zu yin="sà" zi="萨" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="rén" zi="仁" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="cǐ" zi="此" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="jiē" zi="皆" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="nán" zi="南" mdxType="Zu" />
  <Zu yin="yán" zi="阎" mdxType="Zu" />
  <Zu yin="fú" zi="浮" mdxType="Zu" />
  <Zu yin="tí" zi="提" mdxType="Zu" />
  <Zu yin="xíng" zi="行" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yè" zi="业" mdxType="Zu" />
  <Zu yin="ɡǎn" zi="感" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="yè" zi="业" mdxType="Zu" />
  <Zu yin="lì" zi="力" mdxType="Zu" />
  <Zu yin="shèn" zi="甚" mdxType="Zu" />
  <Zu yin="dà" zi="大" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nénɡ" zi="能" mdxType="Zu" />
  <Zu yin="dí" zi="敌" mdxType="Zu" />
  <Zu yin="xū" zi="须" mdxType="Zu" />
  <Zu yin="mí" zi="弥" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nénɡ" zi="能" mdxType="Zu" />
  <Zu yin="shēn" zi="深" mdxType="Zu" />
  <Zu yin="jù" zi="巨" mdxType="Zu" />
  <Zu yin="hǎi" zi="海" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="nénɡ" zi="能" mdxType="Zu" />
  <Zu yin="zhànɡ" zi="障" mdxType="Zu" />
  <Zu yin="shènɡ" zi="圣" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="ɡù" zi="故" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="mò" zi="莫" mdxType="Zu" />
  <Zu yin="qīnɡ" zi="轻" mdxType="Zu" />
  <Zu yin="xiǎo" zi="小" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǐ" zi="以" mdxType="Zu" />
  <Zu yin="wéi" zi="为" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="sǐ" zi="死" mdxType="Zu" />
  <Zu yin="hòu" zi="后" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="xiān" zi="纤" mdxType="Zu" />
  <Zu yin="háo" zi="毫" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="fù" zi="父" mdxType="Zu" />
  <Zu yin="zǐ" zi="子" mdxType="Zu" />
  <Zu yin="zhì" zi="至" mdxType="Zu" />
  <Zu yin="qīn" zi="亲" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qí" zi="歧" mdxType="Zu" />
  <Zu yin="lù" zi="路" mdxType="Zu" />
  <Zu yin="ɡè" zi="各" mdxType="Zu" />
  <Zu yin="bié" zi="别" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zònɡ" zi="纵" mdxType="Zu" />
  <Zu yin="rán" zi="然" mdxType="Zu" />
  <Zu yin="xiānɡ" zi="相" mdxType="Zu" />
  <Zu yin="fénɡ" zi="逢" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="kěn" zi="肯" mdxType="Zu" />
  <Zu yin="dài" zi="代" mdxType="Zu" />
  <Zu yin="shòu" zi="受" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="jīn" zi="今" mdxType="Zu" />
  <Zu yin="chénɡ" zi="承" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="wēi" zi="威" mdxType="Zu" />
  <Zu yin="lì" zi="力" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="luè" zi="略" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wéi" zi="唯" mdxType="Zu" />
  <Zu yin="yuàn" zi="愿" mdxType="Zu" />
  <Zu yin="rén" zi="仁" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="zàn" zi="暂" mdxType="Zu" />
  <Zu yin="tīnɡ" zi="听" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="pǔ" zi="普" mdxType="Zu" />
  <Zu yin="xián" zi="贤" mdxType="Zu" />
  <Zu yin="dā" zi="答" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="wú" zi="吾" mdxType="Zu" />
  <Zu yin="yǐ" zi="已" mdxType="Zu" />
  <Zu yin="jiǔ" zi="久" mdxType="Zu" />
  <Zu yin="zhī" zi="知" mdxType="Zu" />
  <Zu yin="sān" zi="三" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wànɡ" zi="望" mdxType="Zu" />
  <Zu yin="rén" zi="仁" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="lìnɡ" zi="令" mdxType="Zu" />
  <Zu yin="hòu" zi="后" mdxType="Zu" />
  <Zu yin="shì" zi="世" mdxType="Zu" />
  <Zu yin="mò" zi="末" mdxType="Zu" />
  <Zu yin="fǎ" zi="法" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="qiè" zi="切" mdxType="Zu" />
  <Zu yin="è" zi="恶" mdxType="Zu" />
  <Zu yin="xínɡ" zi="行" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="shēnɡ" zi="生" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wén" zi="闻" mdxType="Zu" />
  <Zu yin="rén" zi="仁" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shǐ" zi="使" mdxType="Zu" />
  <Zu yin="lìnɡ" zi="令" mdxType="Zu" />
  <Zu yin="ɡuī" zi="归" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="zànɡ" zi="藏" mdxType="Zu" />
  <Zu yin="bái" zi="白" mdxType="Zu" />
  <Zu yin="yán" zi="言" mdxType="Zu" />
  <Zu yin="：" zi="：" mdxType="Zu" />
  <Zu yin="rén" zi="仁" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qí" zi="其" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qǔ" zi="取" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="shé" zi="舌" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shǐ" zi="使" mdxType="Zu" />
  <Zu yin="niú" zi="牛" mdxType="Zu" />
  <Zu yin="ɡēnɡ" zi="耕" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="qǔ" zi="取" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="xīn" zi="心" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yè" zi="夜" mdxType="Zu" />
  <Zu yin="chā" zi="叉" mdxType="Zu" />
  <Zu yin="shí" zi="食" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="huò" zi="镬" mdxType="Zu" />
  <Zu yin="tānɡ" zi="汤" mdxType="Zu" />
  <Zu yin="shènɡ" zi="盛" mdxType="Zu" />
  <Zu yin="fèi" zi="沸" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhǔ" zi="煮" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="shēn" zi="身" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="chì" zi="赤" mdxType="Zu" />
  <Zu yin="shāo" zi="烧" mdxType="Zu" />
  <Zu yin="tónɡ" zi="铜" mdxType="Zu" />
  <Zu yin="zhù" zi="柱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shǐ" zi="使" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="bào" zi="抱" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shǐ" zi="使" mdxType="Zu" />
  <Zu yin="zhū" zi="诸" mdxType="Zu" />
  <Zu yin="huǒ" zi="火" mdxType="Zu" />
  <Zu yin="shāo" zi="烧" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="chèn" zi="趁" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="rén" zi="人" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yí" zi="一" mdxType="Zu" />
  <Zu yin="xiànɡ" zi="向" mdxType="Zu" />
  <Zu yin="hán" zi="寒" mdxType="Zu" />
  <Zu yin="bīnɡ" zi="冰" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="xiàn" zi="限" mdxType="Zu" />
  <Zu yin="fèn" zi="粪" mdxType="Zu" />
  <Zu yin="niào" zi="尿" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="chún" zi="纯" mdxType="Zu" />
  <Zu yin="fēi" zi="飞" mdxType="Zu" />
  <Zu yin="jí" zi="钑" mdxType="Zu" />
  <Zu yin="lí" zi="鑗" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="duō" zi="多" mdxType="Zu" />
  <Zu yin="cuán" zi="攒" mdxType="Zu" />
  <Zu yin="huǒ" zi="火" mdxType="Zu" />
  <Zu yin="qiānɡ" zi="枪" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wéi" zi="唯" mdxType="Zu" />
  <Zu yin="zhuànɡ" zi="撞" mdxType="Zu" />
  <Zu yin="xiōnɡ" zi="胸" mdxType="Zu" />
  <Zu yin="bèi" zi="背" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="dàn" zi="但" mdxType="Zu" />
  <Zu yin="shāo" zi="烧" mdxType="Zu" />
  <Zu yin="shǒu" zi="手" mdxType="Zu" />
  <Zu yin="zú" zi="足" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="pán" zi="盘" mdxType="Zu" />
  <Zu yin="jiǎo" zi="缴" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="shé" zi="蛇" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qū" zi="驱" mdxType="Zu" />
  <Zu yin="zhú" zi="逐" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="ɡǒu" zi="狗" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="huò" zi="或" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="jìn" zi="尽" mdxType="Zu" />
  <Zu yin="jià" zi="驾" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="luó" zi="骡" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="rén" zi="仁" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡè" zi="各" mdxType="Zu" />
  <Zu yin="ɡè" zi="各" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="zhǒnɡ" zi="种" mdxType="Zu" />
  <Zu yin="yè" zi="业" mdxType="Zu" />
  <Zu yin="dào" zi="道" mdxType="Zu" />
  <Zu yin="zhī" zi="之" mdxType="Zu" />
  <Zu yin="qì" zi="器" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="wú" zi="无" mdxType="Zu" />
  <Zu yin="fēi" zi="非" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="tónɡ" zi="铜" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="tiě" zi="铁" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="shí" zi="石" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="huǒ" zi="火" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="cǐ" zi="此" mdxType="Zu" />
  <Zu yin="sì" zi="四" mdxType="Zu" />
  <Zu yin="zhǒnɡ" zi="种" mdxType="Zu" />
  <Zu yin="wù" zi="物" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="zhònɡ" zi="众" mdxType="Zu" />
  <Zu yin="yè" zi="业" mdxType="Zu" />
  <Zu yin="xínɡ" zi="行" mdxType="Zu" />
  <Zu yin="ɡǎn" zi="感" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="ɡuǎnɡ" zi="广" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="dì" zi="地" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="zuì" zi="罪" mdxType="Zu" />
  <Zu yin="bào" zi="报" mdxType="Zu" />
  <Zu yin="děnɡ" zi="等" mdxType="Zu" />
  <Zu yin="shì" zi="事" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="yī" zi="一" mdxType="Zu" />
  <Zu yin="yī" zi="一" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="zhōnɡ" zi="中" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="ɡènɡ" zi="更" mdxType="Zu" />
  <Zu yin="yǒu" zi="有" mdxType="Zu" />
  <Zu yin="bǎi" zi="百" mdxType="Zu" />
  <Zu yin="qiān" zi="千" mdxType="Zu" />
  <Zu yin="zhǒnɡ" zi="种" mdxType="Zu" />
  <Zu yin="kǔ" zi="苦" mdxType="Zu" />
  <Zu yin="chǔ" zi="楚" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="hé" zi="何" mdxType="Zu" />
  <Zu yin="kuànɡ" zi="况" mdxType="Zu" />
  <Zu yin="duō" zi="多" mdxType="Zu" />
  <Zu yin="yù" zi="狱" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="wǒ" zi="我" mdxType="Zu" />
  <Zu yin="jīn" zi="今" mdxType="Zu" />
  <Zu yin="chénɡ" zi="承" mdxType="Zu" />
  <Zu yin="fó" zi="佛" mdxType="Zu" />
  <Zu yin="wēi" zi="威" mdxType="Zu" />
  <Zu yin="shén" zi="神" mdxType="Zu" />
  <Zu yin="jí" zi="及" mdxType="Zu" />
  <Zu yin="rén" zi="仁" mdxType="Zu" />
  <Zu yin="zhě" zi="者" mdxType="Zu" />
  <Zu yin="wèn" zi="问" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="luè" zi="略" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="rú" zi="如" mdxType="Zu" />
  <Zu yin="shì" zi="是" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
  <Zu yin="ruò" zi="若" mdxType="Zu" />
  <Zu yin="ɡuǎnɡ" zi="广" mdxType="Zu" />
  <Zu yin="jiě" zi="解" mdxType="Zu" />
  <Zu yin="shuō" zi="说" mdxType="Zu" />
  <Zu yin="，" zi="，" mdxType="Zu" />
  <Zu yin="qiónɡ" zi="穷" mdxType="Zu" />
  <Zu yin="jié" zi="劫" mdxType="Zu" />
  <Zu yin="bú" zi="不" mdxType="Zu" />
  <Zu yin="jìn" zi="尽" mdxType="Zu" />
  <Zu yin="。" zi="。" mdxType="Zu" />
    </Text>
    <PrevNext prev='/dz/v4' next='/dz/v6' mdxType="PrevNext" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      